<template>
  <div class="w-full h-full pt-2">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="flex">
        <back-button
          label="Back"
          @onClick="$router.back()"
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878E99; height:1.5rem"
        />
        <h1 class="text-xl  text-left font-extrabold ml-4 mt-3 mr-8">
          {{ role.name }}
        </h1>
      </div>
      <div class=" mx-3">
        <Card class=" p-5">
          <p class="font-bold text-lg text-darkPurple">
            Role Details
          </p>
          <div class="grid grid-cols-12 gap-7 mt-6">
            <div class="col-span-6">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Role name"
                v-model="role.name"
              />
            </div>
            <div class="col-span-6">
              <c-text
                placeholder="--Enter--"
                label="Role Description (Optional)"
                v-model="role.description"
                variant="w-full"
              />
            </div>
          </div>
        </Card>
        <Card class="mb-6 p-5">
          <p class="font-bold text-lg text-darkPurple">
            Access Control
          </p>
          <div class="text-jet flex">
            <p class="flex-grow">
              Select which apps and associated actions/forms to grant access to
            </p>
            <div class="flex">
              <button @click="openModal = true" class="flex">
                <Icon size="s" icon-name="icon-plus" />
                <p class="ml-3 text-flame font-semibold">
                  Add Apps
                </p>
              </button>
              <div class="seperator mx-6" />
              <button
                v-feature-request
                @click="openPrivilegesModal = true"
                class="flex"
              >
                <Icon size="s" icon-name="icon-copy" />
                <p class="ml-3 font-semibold text-blueCrayola">
                  Import Privileges
                </p>
              </button>
            </div>
          </div>
          <p class="text-romanSilver opacity-50 my-2 text-xs font-semibold">
            APPS
          </p>
          <accordion
            v-for="(roleModule, index) in selectedModules"
            :key="index"
            :index="index"
            :open-one-only="true"
            :opened-index="openedIndex"
            @open="isAccordionOpen($event)"
            class="accordionContainer"
          >
            <template v-slot:title>
              <div class="flex items-center">
                <div>
                  <Icon
                    icon-name="delete"
                    class=" text-desire mr-3 cursor-pointer"
                    @click.native="deleteModule(roleModule.id)"
                    size="xsm"
                  />
                </div>
                <custom-image :slug="roleModule.slug" class="mr-3" />
                <div class="flex flex-col flex-grow">
                  <p class="font-semibold">
                    {{ roleModule.name }}
                  </p>
                  <p
                    class="text-xs font-semibold text-romanSilver opacity-50 uppercase "
                  >
                    {{ `• ${roleModule.slug}` }}
                  </p>
                </div>
              </div>
            </template>
            <Tab
              :tabs="roleModule.submodules.map(item => item.name)"
              border
              class="overflow-x-auto"
              :active-tab="currentTab"
              @currentTab="handleTabChange($event, roleModule.submodules)"
            />
            <Card v-if="currentTab === subModuleName" class=" p-2">
              <p
                v-if="currentTab"
                class=" text-xs text-romanSilver font-extrabold"
              >
                ACTIONS, PRIVILEGES AND FORMS
              </p>
              <table
                v-if="currentTab"
                aria-label="privileges_table"
                class="w-full"
              >
                <tbody>
                  <tr>
                    <td>
                      <checkbox
                        label="Grant Access to All Privileges in this sub-app"
                        checkbox-size="height:16px; width:16px; margin-top:4px"
                        label-style="color:#333333"
                        value="all"
                        v-model="addMultiple"
                      />
                      <divider class="my-5" border-dash />
                    </td>
                  </tr>
                  <tr v-for="(item, i) in privileges" :key="i">
                    <td>
                      <checkbox
                        :label="item.name"
                        :value="item.id"
                        checkbox-size="height:16px; width:16px; margin-top:4px"
                        label-style="color:#333333"
                        v-model="selectedPrivileges"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </accordion>
          <button @click="openModal = true" class="flex mt-5 mb-2">
            <Icon size="s" icon-name="icon-plus" />
            <p class="ml-3 text-flame font-semibold">
              Add Apps
            </p>
          </button>
        </Card>
        <div class="">
          <div class="flex mt-4">
            <Button @click="updateRole" 
              class="bg-dynamicBackBtn text-white buttons"
            >
              Save
            </Button>
            <Button @click="cancel" class="buttons">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
    <modules
      @close="openModal = false"
      :open-modal="openModal"
      :modules-data="modules"
      :selected-modules="selectedModules"
      @selectedModules="handleModules($event)"
      @updateModules="updateModules($event)"
    />

    <import-privileges
      @close="openPrivilegesModal = false"
      :open-modal="openPrivilegesModal"
    />
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import { mapState } from "vuex";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import fp from "lodash/fp";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@/components/Card";
import Accordion from "@/components/Accordion";
import CustomImage from "@/components/CustomImage";
import Divider from "@/components/divider";
import Checkbox from "@/components/Checkbox";
import Modules from "./addModules";
import ImportPrivileges from "./importPrivileges";

export default {
  name: "Role",
  components: {
    BackButton,
    Card,
    CText,
    Modules,
    Tab,
    Checkbox,
    CustomImage,
    Divider,
    ImportPrivileges,
    Accordion
  },
  data() {
    return {
      openModal: false,
      openedIndex: 0,
      openPrivilegesModal: false,
      currentTab: "",
      privileges: [],
      selectedModules: [],
      subModuleName: "",
      selectedPrivileges: []
    };
  },
  computed: {
    ...mapState({
      loading: state => state.permission.loading,
      modules: state => state.permission.modules,
      role: state => state.permission.role,
    }),
    addMultiple: {
      get() {
        const newArry = this.privileges.filter(item =>
          this.selectedPrivileges.includes(item.id)
        );
        return newArry ? newArry.length === this.privileges.length : false;
      },
      set(value) {
        const checked = [];
        if (value) {
          this.privileges.forEach(privilege => {
            checked.push(privilege.id);
          });
        } else {
          this.privileges.forEach(privilege => {
            this.selectedPrivileges.splice(
              this.selectedPrivileges.indexOf(privilege.id)
            );
          });
        }
        this.selectedPrivileges = [...this.selectedPrivileges, ...checked];
      }
    }
  },
  methods: {
    handleModules(modules) {
      this.openModal = false;
      if (this.selectedModules.length) {
        modules.forEach(module => {
          if (!this.selectedModules.find(item => item.id === module.id)) {
            this.selectedModules.push(module);
          }
        });
      } else {
        this.selectedModules = modules;
      }
    },
    updateModules(selected){
      this.$store.dispatch("permission/updateModules", selected);
    },
    isAccordionOpen(isOpen) {
      this.currentTab = "";
      this.openedIndex = isOpen;
      if (isOpen) {
        this.currentTab = "";
      }
    },
    handleTabChange(tabName, subModule) {
      this.currentTab = tabName;
      const getSubModule = fp.compose(
        fp.filter(this.getSelectedItems),
        fp.get("privileges"),
        fp.find("privileges"),
        fp.filter(this.addPrivileges)
      );
      getSubModule(subModule);
    },
    // eslint-disable-next-line consistent-return
    addPrivileges(item) {
      if (item.name === this.currentTab) {
        this.privileges = item.privileges;
        this.subModuleName = item.name;
        return item;
      }
    },

    getSelectedItems(item) {
      if (item.hasPrivilege) {
        if (!this.selectedPrivileges.includes(item.id)) {
          this.selectedPrivileges.push(item.id);
        }
      }
    },

    deleteModule(id) {
      this.selectedModules.forEach((module, index) => {
        if (module.id === id) {
          module.submodules.forEach(subModule => {
            subModule.privileges.forEach(privilege => {
              if (this.selectedPrivileges.includes(privilege.id)) {
                this.selectedPrivileges.splice(
                  this.selectedPrivileges.indexOf(privilege.id),
                  1
                );
              }
            });
          });
          this.selectedModules.splice(index, 1);
        }
      });
    },
    cancel() {
      if (this.$route.params.id) {
        this.$router.push({
          name: "RolesAndPrivileges"
        });
      } else {
        this.reset();
        this.$router.push({
          name: "RolesAndPrivileges"
        });
      }
    },
    // eslint-disable-next-line consistent-return
    updateRole() {
      if (this.role.name === "") {
        this.$store.commit("SET_TOAST", {
          text: `Name and Description can't be empty`,
          color: "error"
        });

        return null;
      }
      const payload = {
        roleId: this.$route.params.id,
        orgId: this.$AuthUser.orgId,
        name: this.role.name,
        description: this.role.description,
        rolePrivileges: this.selectedPrivileges.map(resp => ({
          privilegeId: resp
        }))
      };
      return this.$_NewUpdateRoles(payload).then(({ data }) => {
        this.$router.push({ name: "RolesAndPrivileges" })
        const message = "Role updated successfully!"
        this.$toasted.success(data.message || message, { duration: 5000 })
      }).catch((error) => {
        const message = "Something went wrong, please try again!"
        this.$toasted.error(error || message, { duration: 5000 })
        throw new Error(error)
      })
    },
    addAlreadySelectedPrivileges() {
      this.modules.forEach(module => {
        module.submodules.reduce(this.reducer, []);
      });
    },
    reducer(acc, current) {
      const hasPrivileges = current.privileges.filter(
        item => item.hasPrivilege
      );
      if (hasPrivileges.length) {
        hasPrivileges.forEach(privilege => {
          this.selectedPrivileges.push(privilege.id);
        });
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(
      "permission/getRolesModules",
      this.$route.params.id
    );
    this.modules.forEach(item => {
      if (item.hasPrivilege) {
        this.selectedModules.push(item);
      }
    });

    this.addAlreadySelectedPrivileges();
  }
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.seperator {
  border: 1px solid #878e99;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}
.accordionContainer {
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 5px;
}
td {
  border: none;
  padding: 12px;
  min-height: 43px !important;
}
tr:nth-child(even) {
  background-color: #f7f7ff;
}
</style>